import React from 'react';
import { StyledTextPage } from './TextPage.styled';
import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import { SocialContact } from '~/components/Generic/SocialContact/SocialContact';

import { ContentfulPage } from '~/types/types.d';

export const TextPage: React.FC<TextPageProps> = ({ page }) => {
  return (
    <StyledTextPage>
      <Wrapper width="normal">
        <h1>{page?.name}</h1>

        <article>
          {page?.sections.map(section => {
            return (
              <section
                key={`textpage-section-${section.id}`}
                dangerouslySetInnerHTML={{
                  __html: section?.description?.childMarkdownRemark?.html,
                }}
              />
            );
          })}
        </article>
      </Wrapper>

      <Section>
        <Wrapper width="wide" bg="gray">
          <SocialContact />
        </Wrapper>
      </Section>
    </StyledTextPage>
  );
};

interface TextPageProps {
  page: ContentfulPage;
}
