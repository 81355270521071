import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout/Layout';
import { TextPage } from '~/components/TextPage/TextPage';
import Metadata from '~/components/Metadata';
import { ContentfulPage } from '~/types/types.d';

const StaticPageTemplate: React.FC<StaticPageTemplateProps> = ({ data }) => {
  const { page } = data;

  return (
    <Layout>
      <Metadata title={page.name} />
      <TextPage page={page} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query StaticPageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;

export default StaticPageTemplate;

interface StaticPageTemplateProps {
  data: {
    page: ContentfulPage;
  };
}
