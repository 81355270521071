import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledTextPage = styled.div`
  --borderColor: rgba(19, 19, 19, 0.1);
  padding: 20px;

  & h1 {
    font-weight: 300;
    font-size: ${pxtorem(40)};
    line-height: 1;
    letter-spacing: -0.01em;
    margin: 0 0 1em;

    @media (min-width: 768px) {
      font-size: ${pxtorem(60)};
    }
  }

  & article {
    padding: 20px 0 60px;
    font-size: ${pxtorem(12)};
    font-weight: 400;

    & h2,
    & h3 {
      font-size: ${pxtorem(16)};
      font-weight: 500;
      text-transform: uppercase;
      padding: 30px 0 0;
      margin: 30px 0;
      border-top: 1px solid var(--borderColor);

      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      & + p,
      & + ul,
      & + ol {
        margin-top: 0;
      }

      & a {
        color: currentColor;
      }
    }

    & ol,
    & ul {
      padding-left: 1em;
    }

    & section {
      border: 1px solid var(--borderColor);
      border-width: 1px 0 0;

      &:last-child {
        border-bottom-width: 1px;
      }
    }
  }

  @media (min-width: 768px) {
    article {
      display: flex;
      justify-content: flex-end;
      font-size: ${pxtorem(20)};
      padding: 30px 0 120px;

      & > section {
        flex: 0 1 50%;
        max-width: 620px;
      }
    }
  }
`;
